import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {catchError, Observable, of} from "rxjs";
import {environment} from "../../../environments/environment";
import {mapOneOrManyArgs} from "rxjs/internal/util/mapOneOrManyArgs";
import {Media} from "../interfaces/media";
import {CommonService} from "../../shared/services/common-service";

@Injectable({
  providedIn: 'root'
})
export class MediaService extends CommonService{

  constructor(http: HttpClient) {
    super('/service_media', http)
  }

  getMediaById(id: string): Observable<Media | null> {
    let url: string = environment.postgrestBaseUrl + this.baseUrl;
    let params = new HttpParams();
    params = params.append('id', 'eq.' + id);
    return this.http.get<Media>(url, {params: params})
      .pipe(mapOneOrManyArgs(media => media ? media : null),
        catchError(() => of(null)));
  }

  getMediaByServiceIdAndFilter(serviceId: string, filter?: string): Observable<Media[]> {
    let url: string = environment.postgrestBaseUrl + this.baseUrl;
    let params = new HttpParams();
    params = params.append('service_id', 'eq.' + serviceId);
    if (filter){
      params = params.append('file_url', 'imatch.' + filter);
      return this.http.get<Media[]>(url, {params: params});
    }
    return this.http.get<Media[]>(url, {params: params})
  }

  createMedia(media: Media): Observable<void> {
    let url: string = environment.postgrestBaseUrl + this.baseUrl;
    return this.http.post<void>(url, media);
  }

  updateMedia(media: Media):Observable<void> {
    let url: string = environment.postgrestBaseUrl + this.baseUrl;
    let params = new HttpParams();
    params = params.append('id', 'eq.' + media.id);
    return this.http.patch<void>(url, media, {params: params});
  }

  uploadMediaInFS(serviceId: string, file: File): Observable<any>{
    const uploadUrl: string = environment.fileSystemBaseUrl + '/' + serviceId + '/upload';
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post<any>(uploadUrl, formData);
  }

  updateMediaInFS(serviceId: string, file: File, oldFileUrl: string): Observable<any>{
    const updateUrl: string = environment.fileSystemBaseUrl + '/' + serviceId + '/update';
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('oldFileUrl', oldFileUrl);
    return this.http.patch<any>(updateUrl, formData);
  }

  deleteMediaInFS(storageId: string): Observable<any>{
    const deleteUrl: string = environment.fileSystemBaseUrl + '/delete';
    let params = new HttpParams();
    params = params.append('storageId', storageId);
    return this.http.delete<any>(deleteUrl, {params: params});
  }

  deleteFolderServiceInFS(serviceId: string): Observable<any>{
    const deleteUrl: string = environment.fileSystemBaseUrl + '/deleteFolder';
    let params = new HttpParams();
    params = params.append('serviceId', serviceId);
    return this.http.delete<any>(deleteUrl, {params: params});
  }

  previewImage(fileUrl: string): Observable<any> {
    const previewUrl: string = environment.fileSystemBaseUrl + '/preview';
    let params = new HttpParams();
    params = params.append('fileUrl', fileUrl);
    return this.http.get<any>(previewUrl, {params: params});
  }
}
