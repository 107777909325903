import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dropdownPipe'
})
export class DropdownPipePipe implements PipeTransform {

  transform(value: any, mapping: { [key: string]: string }): string {
    return mapping[value] || value;
  }

}
