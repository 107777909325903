import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LinkUrlButton} from "../../interfaces/link-url-button";

@Component({
  selector: 'app-actions-buttons',
  templateUrl: './actions-buttons.component.html',
  styles: ``
})
export class ActionsButtonsComponent {

  @Input()
  linkUrlButtons: LinkUrlButton[] = [];

  @Output()
  eventAction = new EventEmitter<void>();

  eventClick(): void {
    this.eventAction.emit();
  }
}
