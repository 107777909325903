import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";

export abstract class CommonService {

  protected constructor(protected baseUrl: string,
                        protected http: HttpClient,
                        protected idField?: string) {}

  deleteById(id: any): Observable<void> {
    let url: string = environment.postgrestBaseUrl + this.baseUrl;
    let params = new HttpParams();
    params = params.append(this.idField ? this.idField : 'id', 'eq.' + id);
    return this.http.delete<void>(url, {params: params});
  }
}
