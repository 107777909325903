<div class="btn-group">
  <button type="button" class="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
    Actions
  </button>
  <ul class="dropdown-menu">
    <li *ngFor="let linkUrlButton of linkUrlButtons">
      <ng-container *ngIf="!linkUrlButton.hasEvent">
        <a hasAnyRole [viewRoles]="linkUrlButton.viewRoles" class="dropdown-item" [routerLink]="linkUrlButton.linkUrl">{{ linkUrlButton.labelButton }}</a>
      </ng-container>
      <ng-container *ngIf="linkUrlButton.hasEvent">
        <button hasAnyRole [viewRoles]="linkUrlButton.viewRoles" class="dropdown-item text-danger" (click)="eventClick()">
          {{ linkUrlButton.labelButton }}
        </button>
      </ng-container>
    </li>
  </ul>
</div>
